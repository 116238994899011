import { useEffect, useState } from "react";
// import { Sidebar } from "../../components/Sidebar";
// import { Header } from "../../components/Header";
// import { Footer } from "../../components/Footer";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { UserInfo } from "../../types/common";
import { local } from "../../helper/localStorage";
import { getInfoAccount, updateInfoAccount } from "../../redux/slices/userSlice";
import overLoader from "../../components/overLoader";
import { Button, Col, DatePicker, Form, Input, Row, Select, Tabs, TabsProps, Upload, UploadProps } from "antd";
import dayjs from "dayjs";
import selectList from "../../utils/common";
import images from "../../assets/images/index";
import { RcFile } from "antd/es/upload";
import { userApi } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import showMessage from "../../components/openMessage";

export const CustomerInfo = () => {
  //   const [api, showPopup] = notification.useNotification();
  const [form] = Form.useForm();
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState({
    image_front: "",
    image_end: "",
    image_face: "",
  });
  const languageLocal = local.StorageGetSecure("language");
  const { t } = useTranslation(["customer_info", "common"]);

  // redux
  const dispatch = useAppDispatch();
  const authLoading = useAppSelector((state) => state.auth.loading);
  const userLoading = useAppSelector((state) => state.user.loading);
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  const token: any = local.StorageGetSecure("access_token");
  useEffect(() => {
    if (token) {
      dispatch(getInfoAccount());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userInfo) {
      form.setFieldsValue({
        name: userInfo?.name,
        name_kana: userInfo?.name_kana,
        // last_name: userInfo?.name?.split(" ")[0],
        // first_name: userInfo?.name?.slice(userInfo?.name.indexOf(" ") + 1),
        // last_name_kana: userInfo?.name_kana,
        // first_name_kana: userInfo?.name_kana,
        birth_day: userInfo?.birth_day ? dayjs(userInfo.birth_day) : null,
        gender: userInfo?.gender,
        phone: userInfo?.phone,
        email: userInfo?.email,
        post_code: userInfo?.post_code,
        prefecture: userInfo?.prefecture,
        city: userInfo?.city,
        address: userInfo?.address,
        area: userInfo?.area,
        building: userInfo?.building,
        payment_method: userInfo?.shipping[0]?.payment_method,
        payment_code: userInfo?.shipping[0]?.payment_code,
        shipping_method: userInfo?.shipping[0]?.shipping_method !== 0 ? userInfo?.shipping[0]?.shipping_method : null,
        shipping_code: userInfo?.shipping[0]?.shipping_code,
        shipping_post_code: userInfo?.shipping[0]?.post_code,
        shipping_address01: userInfo?.shipping[0]?.address01,
        passport: selectList.countryList.find((item) => item.value === userInfo?.passport)?.value,
        //   languageLocal === "ja"
        //     ? selectListJA.countryList.find((item) => item.value === userInfo?.passport)?.value
        //     : selectListVI.countryList.find((item) => item.value === userInfo?.passport)?.value ||
        //       selectListJA.countryList.find((item) => item.value === userInfo?.passport)?.value,
        iccd: userInfo?.iccd,
        coe: selectList.coeList.find((item) => item.value === userInfo?.coe)?.value,
        // coe:
        //   languageLocal === "ja"
        //     ? selectListJA.coeList.find((item) => item.value === userInfo?.coe)?.value
        //     : selectListVI.coeList.find((item) => item.value === userInfo?.coe)?.value ||
        //       selectListJA.coeList.find((item) => item.value === userInfo?.coe)?.value,
        expire_time_coe: userInfo?.expire_time_coe ? dayjs(userInfo.expire_time_coe) : null,
      });
    }
  }, [userInfo, form, languageLocal]);
  //   console.log(userInfo);

  const handleUpdateForm = async (data: any) => {
    const listField = [
      "name",
      "name_kana",
      "birth_day",
      "gender",
      "phone",
      "email",
      "post_code",
      "prefecture",
      "city",
      "address",
      "area",
      "building",
      "shipping",
      "passport",
      "iccd",
      "coe",
      "expire_time_coe",
    ];
    let dataSend = {
      ...listField.reduce((acc: any, field: string) => {
        if (userInfo[field] !== undefined) {
          acc[field] = userInfo[field];
        }
        return acc;
      }, {}),
      ...data,
    };
    if (userInfo?.verify?.length === 0) {
      //   dataSend = data;
      // } else {
      dataSend = {
        ...listField.reduce((acc: any, field: string) => {
          if (userInfo[field] !== undefined) {
            acc[field] = userInfo[field];
          }
          return acc;
        }, {}),
        ...data,
        image_front_base64: imageBase64.image_front,
        image_end_base64: imageBase64.image_end,
        image_face_base64: imageBase64.image_face,
      };
    }
    setOpenEdit(false);
    // console.log(dataSend);
    try {
      const resData: any = await dispatch(updateInfoAccount(dataSend));
      dispatch(getInfoAccount());
      if (resData.payload.code === 200) {
        showMessage.success(resData?.payload?.msg);
        form.resetFields();
      } else {
        showMessage.error(resData?.payload?.msg);
      }
    } catch (error: any) {
      showMessage.error(error?.payload?.msg);
    }
  };
  const handleFinishFailed = (errorInfo: any) => {
    form.getFieldInstance(errorInfo.errorFields[0].name)?.focus();
  };
  const handleUpperCase = (e: any) => {
    e.target.value = e.target.value.toUpperCase();
  };

  //   const statusVerify = userInfo?.verify?.length === 0 ? false : true;
  let statusVerify = 0;
  if (userInfo?.verify?.length === 0) {
    statusVerify = 0;
  } else if (userInfo?.verify?.length > 0) {
    switch (userInfo?.verify[0].status) {
      case 1:
        statusVerify = 1;
        break;
      case 2:
        statusVerify = 2;
        break;
      default:
        statusVerify = 0;
        break;
    }
  } else {
    statusVerify = userInfo?.status;
  }

  const getBase64 = (img: RcFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const propsUpload = (field: string): UploadProps => ({
    listType: "picture",
    maxCount: 1,
    beforeUpload: async (file: RcFile) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
      if (!isJpgOrPng) {
        showMessage.error("Vui lòng chọn file ảnh có định dạng jpg hoặc png");
        return Upload.LIST_IGNORE;
      }
      const base64 = await getBase64(file);
      setImageBase64({ ...imageBase64, [field]: base64 });
      return false;
    },
  });

  const handlePostCodeChange = async (e: any) => {
    // console.log(e.target.id);
    const postCode = e.target.value;
    if (postCode.length === 7) {
      try {
        const resData: any = await userApi.getAddressByPostCode(postCode);
        const address = resData.data.data[0].ja;
        if (e.target.id === "post_code") {
          form.setFieldsValue({
            prefecture: address.prefecture,
            city: address.address1,
            address: address.address2,
          });
        } else if (e.target.id === "shipping_post_code") {
          form.setFieldsValue({
            shipping_address01: address.prefecture + address.address1 + address.address2,
          });
        }
      } catch (error: any) {
        if (error.message === "Request failed with status code 404") {
          form.setFields([{ name: "post_code", errors: ["Vui lòng nhập đúng postcode"] }]);
        }
      }
    }
  };
  const tabsItem: TabsProps["items"] = [
    {
      key: "user_info",
      //   label: "個人情報",
      label: t("thong_tin_ca_nhan"),
      children: userInfo ? (
        <Form
          layout="vertical"
          disabled={!openEdit}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 24 }}
          //   wrapperCol={{ span: 24 }}
          form={form}
          initialValues={{
            name: userInfo?.name || "––",
            name_kana: userInfo?.name_kana || "––",
            birth_day: userInfo?.birth_day ? dayjs(userInfo.birth_day) : "––",
            gender: userInfo?.gender || "––",
            phone: userInfo?.phone || "––",
            email: userInfo?.email || "––",
          }}
          scrollToFirstError
          onFinish={handleUpdateForm}
          onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            // label="フルネーム"
            label={t("ho_ten")}
            name="name"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ho_ten")}` },
              {
                pattern: /^[A-Z ]+$/,
                message: t("yeu_cau_ho_ten"),
              },
            ]}
          >
            <Input type="text" placeholder={t("ho_ten")} onInput={handleUpperCase} />
          </Form.Item>
          <Form.Item
            // label="フルネーム (カナ)"
            label={t("ho_ten_kana")}
            name="name_kana"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ho_ten_kana")}` },
              {
                pattern: /^([ァ-ン]|ー)+$/,
                message: t("yeu_cau_ho_ten_kana"),
              },
            ]}
          >
            <Input type="text" placeholder={t("ho_ten_kana")} />
          </Form.Item>
          <Form.Item
            //   label="生年月日"
            label={t("ngay_sinh")}
            name="birth_day"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("ngay_sinh")}` }]}
          >
            <DatePicker className="customer-form-input" format="YYYY-MM-DD" placeholder={t("ngay_sinh")} />
          </Form.Item>
          <Form.Item
            //   label="性別"
            label={t("gioi_tinh")}
            name="gender"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("gioi_tinh")}` }]}
          >
            <Select options={selectList.genderList} className="customer-form-input" />
            {/* {languageLocal === "ja" ? (
              <Select options={selectListJA.genderList} placeholder={t("gioi_tinh")} />
            ) : (
              <Select options={selectListVI.genderList} placeholder={t("gioi_tinh")} /> || (
                <Select options={selectListJA.genderList} placeholder={t("gioi_tinh")} />
              )
            )} */}
          </Form.Item>
          <Form.Item
            // label="電話番号"
            label={t("so_dien_thoai")}
            name="phone"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("so_dien_thoai")}` },
              { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
              { min: 10, max: 11, message: t("yeu_cau_so_dien_thoai") },
            ]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("so_dien_thoai")} />
          </Form.Item>
          <Form.Item
            // label="メールアドレス"
            label={t("email")}
            name="email"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("email")}` },
              {
                type: "email",
                message: t("yeu_cau_email"),
              },
            ]}
          >
            <Input type="email" className="customer-form-input" placeholder={t("email")} />
          </Form.Item>
          <Button
            disabled={false}
            onClick={() => setOpenEdit(!openEdit)}
            className="customer-form-edit"
            type={openEdit ? "default" : "primary"}
            htmlType="button"
          >
            {/* {openEdit ? "キャンセル" : "変更"} */}
            {openEdit ? t("common:huy") : t("common:chinh_sua")}
          </Button>
          {openEdit && (
            <Button type="primary" htmlType="submit" className="customer-form-submit">
              {/* 保存 */}
              {t("common:luu")}
            </Button>
          )}
        </Form>
      ) : (
        <></>
      ),
    },
    {
      key: "address_info",
      //   label: "住所情報",
      label: t("thong_tin_dia_chi"),
      children: (
        <Form
          layout="vertical"
          disabled={!openEdit}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 12 }}
          form={form}
          initialValues={{
            post_code: userInfo?.post_code || "––",
            prefecture: userInfo?.prefecture || "––",
            city: userInfo?.city || "––",
            address: userInfo?.address || "––",
            area: userInfo?.area || "––",
            building: userInfo?.building || "––",
          }}
          scrollToFirstError
          onFinish={handleUpdateForm}
          onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            // label="郵便番号"
            label={t("ma_buu_dien")}
            name="post_code"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_buu_dien")}` },
              {
                pattern: /^[0-9]+$/,
                min: 7,
                max: 7,
                message: t("yeu_cau_ma_buu_dien"),
              },
            ]}
          >
            <Input
              type="text"
              inputMode="numeric"
              className="customer-form-input"
              onChange={handlePostCodeChange}
              //   placeholder="Nhập mã bưu điện"
              placeholder={t("ma_buu_dien")}
            />
          </Form.Item>
          <Form.Item
            //   label="都道府県"
            label={t("tinh")}
            name="prefecture"
            rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("tinh")}` }]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("tinh")} />
          </Form.Item>
          <Form.Item
            //   label="市区町村"
            label={t("thanh_pho")}
            name="city"
            rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("thanh_pho")}` }]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("thanh_pho")} />
          </Form.Item>
          <Form.Item
            //   label="住所"
            label={t("dia_chi")}
            name="address"
            rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("dia_chi")}` }]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("dia_chi")} />
          </Form.Item>
          <Form.Item
            //   label="番地"
            label={t("khu_vuc")}
            name="area"
            rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("khu_vuc")}` }]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("khu_vuc")} />
          </Form.Item>
          <Form.Item
            //   label="物件名"
            label={t("toa_nha")}
            name="building"
          >
            <Input type="text" className="customer-form-input" placeholder={t("toa_nha")} />
          </Form.Item>
          <Button
            disabled={false}
            onClick={() => setOpenEdit(!openEdit)}
            className="customer-form-edit"
            type={openEdit ? "default" : "primary"}
            htmlType="button"
          >
            {/* {openEdit ? "キャンセル" : "変更"} */}
            {openEdit ? t("common:huy") : t("common:chinh_sua")}
          </Button>
          {openEdit && (
            <Button type="primary" htmlType="submit" className="customer-form-submit">
              {/* 保存 */}
              {t("common:luu")}
            </Button>
          )}
        </Form>
      ),
    },
    {
      key: "shipping_info",
      //   label: "お届け先情報",
      label: t("thong_tin_giao_hang"),
      children: (
        <Form
          layout="vertical"
          disabled={!openEdit}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 12 }}
          form={form}
          initialValues={{
            payment_method: userInfo?.shipping[0]?.payment_method || "––",
            payment_code: userInfo?.shipping[0]?.payment_code || "––",
            shipping_method:
              userInfo?.shipping[0]?.shipping_method !== 0 ? userInfo?.shipping[0]?.shipping_method : null || "––",
            shipping_code: userInfo?.shipping[0]?.shipping_code || "––",
            shipping_post_code: userInfo?.shipping[0]?.post_code || "––",
            shipping_address01: userInfo?.shipping[0]?.address01 || "––",
          }}
          scrollToFirstError
          onFinish={handleUpdateForm}
          onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            label={t("phuong_thuc_thanh_toan")}
            name="payment_method"
            rules={[
              {
                required: true,
                // type: "string",
                // whitespace: true,
                // message: "Vui lòng chọn phương thức thanh toán",
                message: `${t("common:vui_long_chon")} ${t("phuong_thuc_thanh_toan")}`,
              },
            ]}
          >
            <Select options={selectList.paymentMethodList} placeholder={t("phuong_thuc_thanh_toan")} />
            {/* {languageLocal === "ja" ? (
              <Select options={selectListJA.paymentMethodList} placeholder={t("phuong_thuc_thanh_toan")} />
            ) : (
              <Select options={selectListVI.paymentMethodList} placeholder={t("phuong_thuc_thanh_toan")} /> || (
                <Select options={selectListJA.paymentMethodList} placeholder={t("phuong_thuc_thanh_toan")} />
              )
            )} */}
          </Form.Item>
          <Form.Item
            // label="支払いコード (Smartpit)"
            label={`${t("ma_thanh_toan")} (Smartpit)`}
            name="payment_code"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_thanh_toan")} Smartpit` },
              { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
            ]}
          >
            <Input
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              className="customer-form-input"
              placeholder={`${t("ma_thanh_toan")} (Smartpit)`}
            />
          </Form.Item>
          <Form.Item
            // label="配送方法"
            label={t("phuong_thuc_giao_hang")}
            name="shipping_method"
            rules={[
              {
                required: true,
                // type: "string",
                pattern: /^[1-9]*$/,
                whitespace: true,
                message: `${t("common:vui_long_chon")} ${t("phuong_thuc_giao_hang")}`,
              },
            ]}
          >
            <Select options={selectList.shippingMethodList} placeholder={t("phuong_thuc_giao_hang")} />
            {/* {languageLocal === "ja" ? (
              <Select options={selectListJA.shippingMethodList} placeholder={t("phuong_thuc_giao_hang")} />
            ) : (
              <Select options={selectListVI.shippingMethodList} placeholder={t("phuong_thuc_giao_hang")} /> || (
                <Select options={selectListJA.shippingMethodList} placeholder={t("phuong_thuc_giao_hang")} />
              )
            )} */}
          </Form.Item>
          <Form.Item
            // label="配送コード"
            label={t("ma_giao_hang")}
            name="shipping_code"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_giao_hang")}` },
              {
                pattern: /^[0-9]*$/,
                message: t("yeu_cau_dinh_dang_so"),
              },
            ]}
          >
            <Input type="text" inputMode="numeric" className="customer-form-input" placeholder={t("ma_giao_hang")} />
          </Form.Item>
          <Form.Item label={t("ma_buu_dien")} name="shipping_post_code">
            <Input
              type="text"
              className="customer-form-input"
              placeholder={t("ma_buu_dien")}
              onChange={handlePostCodeChange}
            />
          </Form.Item>
          <Form.Item label={t("dia_chi")} name="shipping_address01">
            <Input type="text" className="customer-form-input" placeholder={t("dia_chi")} />
          </Form.Item>
          <Button
            disabled={false}
            onClick={() => setOpenEdit(!openEdit)}
            className="customer-form-edit"
            type={openEdit ? "default" : "primary"}
            htmlType="button"
          >
            {/* {openEdit ? "キャンセル" : "変更"} */}
            {openEdit ? t("common:huy") : t("common:chinh_sua")}
          </Button>
          {openEdit && (
            <Button type="primary" htmlType="submit" className="customer-form-submit">
              {/* 保存 */}
              {t("common:luu")}
            </Button>
          )}
        </Form>
      ),
    },
    {
      key: "passport_info",
      //   label: "本人確認",
      label: t("thong_tin_cu_tru"),
      children: (
        <Form
          layout="vertical"
          disabled={!openEdit}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 12 }}
          form={form}
          initialValues={{
            passport: userInfo?.passport || "––",
            iccd: userInfo?.iccd || "––",
            coe: userInfo?.coe || "––",
            expire_time_coe: userInfo?.expire_time_coe ? dayjs(userInfo.expire_time_coe) : "––",
          }}
          scrollToFirstError
          onFinish={handleUpdateForm}
          onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            label={t("quoc_tich")}
            name="passport"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("quoc_tich")}` }]}
          >
            <Select options={selectList.countryList} placeholder={t("quoc_tich")} />
            {/* {languageLocal === "ja" ? (
              <Select options={selectListJA.countryList} placeholder={t("quoc_tich")} />
            ) : (
              <Select options={selectListVI.countryList} placeholder={t("quoc_tich")} /> || (
                <Select options={selectListJA.countryList} placeholder={t("quoc_tich")} />
              )
            )} */}
          </Form.Item>
          <Form.Item
            label={t("so_the_cu_tru")}
            name="iccd"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("so_the_cu_tru")}` },
              { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
            ]}
          >
            <Input type="text" inputMode="numeric" className="customer-form-input" placeholder={t("so_the_cu_tru")} />
          </Form.Item>
          <Form.Item
            label={t("hinh_thuc_cu_tru")}
            name="coe"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("hinh_thuc_cu_tru")}` }]}
          >
            <Select options={selectList.coeList} placeholder={t("hinh_thuc_cu_tru")} />
            {/* {languageLocal === "ja" ? (
              <Select options={selectListJA.coeList} placeholder={t("hinh_thuc_cu_tru")} />
            ) : (
              <Select options={selectListVI.coeList} placeholder={t("hinh_thuc_cu_tru")} /> || (
                <Select options={selectListJA.coeList} placeholder={t("hinh_thuc_cu_tru")} />
              )
            )} */}
          </Form.Item>
          <Form.Item
            // label="在留期限"
            label={t("thoi_han_cu_tru")}
            name="expire_time_coe"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("thoi_han_cu_tru")}` }]}
          >
            <DatePicker className="customer-form-input" format="YYYY-MM-DD" placeholder={t("thoi_han_cu_tru")} />
          </Form.Item>
          <Button
            disabled={false}
            onClick={() => setOpenEdit(!openEdit)}
            className="customer-form-edit"
            type={openEdit ? "default" : "primary"}
            htmlType="button"
          >
            {/* {openEdit ? "キャンセル" : "変更"} */}
            {openEdit ? t("common:huy") : t("common:chinh_sua")}
          </Button>
          {openEdit && (
            <Button type="primary" htmlType="submit" className="customer-form-submit">
              {/* 保存 */}
              {t("common:luu")}
            </Button>
          )}
        </Form>
      ),
    },
    ...(userInfo?.verify?.length === 0
      ? [
          {
            key: "verify_image",
            // label: "本人確認画像",
            label: t("thong_tin_xac_thuc"),
            children: (
              <Form
                layout="vertical"
                disabled={!openEdit}
                className="customer-item-info customer-form"
                // labelCol={{ span: 6 }}
                // wrapperCol={{ span: 12 }}
                form={form}
                scrollToFirstError
                onFinish={handleUpdateForm}
                onFinishFailed={handleFinishFailed}
              >
                {/* <Form.Item label="正面" name="image_front">
                        <Upload {...propsUpload("image_front")}>
                        <Button icon={<images.iconUpload />} className="customer-form-upload">
                            アップロード
                        </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="裏面" name="image_end">
                        <Upload {...propsUpload("image_end")}>
                        <Button icon={<images.iconUpload />} className="customer-form-upload">
                            アップロード
                        </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="顔写真" name="image_face">
                        <Upload {...propsUpload("image_face")}>
                        <Button icon={<images.iconUpload />} className="customer-form-upload">
                            アップロード
                        </Button>
                        </Upload>
                    </Form.Item> */}
                <Form.Item
                  label={<span className="customer-form-label">{t("anh_mat_truoc")}</span>}
                  name="image_front"
                  wrapperCol={{ span: 19 }}
                  rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("anh_mat_truoc")}` }]}
                >
                  <div className="customer-form-upload">
                    <Upload {...propsUpload("image_front")} name="image_front">
                      <Button className="customer-form-button">
                        <span className="customer-form-button-icon">
                          <i className="fa-light fa-upload"></i>
                        </span>
                        <span className="customer-form-button-text">{t("common:tai_len")}</span>
                      </Button>
                    </Upload>
                    <div className="customer-form-upload-sample">
                      <img src={images.image_front_sample} alt="" />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  label={<span className="customer-form-label">{t("anh_mat_sau")}</span>}
                  name="image_end"
                  wrapperCol={{ span: 19 }}
                  rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("anh_mat_sau")}` }]}
                >
                  <div className="customer-form-upload">
                    <Upload {...propsUpload("image_end")} name="image_end">
                      <Button className="customer-form-button">
                        <span className="customer-form-button-icon">
                          <i className="fa-light fa-upload"></i>
                        </span>
                        <span className="customer-form-button-text">{t("common:tai_len")}</span>
                      </Button>
                    </Upload>
                    <div className="customer-form-upload-sample">
                      <img src={images.image_end_sample} alt="" />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  label={<span className="customer-form-label">{t("anh_chup_cam_tay")}</span>}
                  name="image_face"
                  wrapperCol={{ span: 19 }}
                  rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("anh_chup_cam_tay")}` }]}
                >
                  <div className="customer-form-upload">
                    <Upload {...propsUpload("image_face")} name="image_face">
                      <Button className="customer-form-button">
                        <span className="customer-form-button-icon">
                          <i className="fa-light fa-upload"></i>
                        </span>
                        <span className="customer-form-button-text">{t("common:tai_len")}</span>
                      </Button>
                    </Upload>
                    <div className="customer-form-upload-sample">
                      <img src={images.image_face_sample} alt="" />
                    </div>
                  </div>
                </Form.Item>
                <Button
                  disabled={false}
                  onClick={() => setOpenEdit(!openEdit)}
                  className="customer-form-edit"
                  type={openEdit ? "default" : "primary"}
                  htmlType="button"
                >
                  {/* {openEdit ? "キャンセル" : "変更"} */}
                  {openEdit ? t("common:huy") : t("common:chinh_sua")}
                </Button>
                {openEdit && (
                  <Button type="primary" htmlType="submit" className="customer-form-submit">
                    {/* 保存 */}
                    {t("common:luu")}
                  </Button>
                )}
              </Form>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="wrapper customer">
      {/* {userInfo ? "" : overLoader()} */}
      {authLoading && overLoader()}
      {userLoading && overLoader()}
      {/* {showPopup} */}
      {/* <Sidebar
        // openSidebar={openSidebar}
        // collapseSidebar={collapseSidebar}
        // handleCollapseSidebar={() => setCollapseSidebar(!collapseSidebar)}
      /> */}
      {/* <div className={`main${collapseSidebar ? " is-collapse" : ""}`}> */}
      {/* <Header handleToggleSidebar={() => setOpenSidebar(!openSidebar)} /> */}
      {/*
              <Form
            className="customer-item-info customer-form"
            //   labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            form={form}
            initialValues={{
              name: userInfo?.name || "––",
              name_kana: userInfo?.name_kana || "––",
              //   last_name: userInfo?.name?.split(" ")[0] || "––",
              //   first_name: userInfo?.name?.slice(userInfo?.name.indexOf(" ") + 1) || "––",
              //   last_name_kana: userInfo?.name_kana || "––",
              //   first_name_kana: userInfo?.name_kana || "––",
              birth_day: userInfo?.birth_day ? dayjs(userInfo.birth_day) : "––",
              gender: userInfo?.gender || "––",
              phone: userInfo?.phone || "––",
              email: userInfo?.email || "––",
              post_code: userInfo?.post_code || "––",
              prefecture: userInfo?.prefecture || "––",
              city: userInfo?.city || "––",
              address: userInfo?.address || "––",
              area: userInfo?.area || "––",
              building: userInfo?.building || "––",
              payment_method: userInfo?.shipping[0]?.payment_method || "––",
              payment_code: userInfo?.shipping[0]?.payment_code || "––",
              shipping_method: userInfo?.shipping[0]?.shipping_method || "––",
              shipping_code: userInfo?.shipping[0]?.shipping_code || "––",
              shipping_post_code: userInfo?.shipping[0]?.post_code || "––",
              shipping_address01: userInfo?.shipping[0]?.address01 || "––",
              passport: selectList.countryList.find((item) => item.value === userInfo?.passport) || "––",
              iccd: userInfo?.iccd || "––",
              coe: selectList.coeList.find((item) => item.value === userInfo?.coe)?.value || "––",
              expire_time_coe: userInfo?.expire_time_coe ? dayjs(userInfo.expire_time_coe) : "––",
            }}
            scrollToFirstError
            onFinish={handleUpdateForm}
            onFinishFailed={handleFinishFailed}
          >
            {!openEdit ? (
              <>
                <div className="customer-item-info-header">
                  <h1 className="customer-item-info-title">個人情報</h1>
                  <div className="customer-item-info-edit" onClick={() => setOpenEdit(true)}>
                    変更
                  </div>
                </div>
                <div className="customer-item-info-detail">
                  <div className="form-group info">
                    <div className="form-item">
                      <h2 className="form-item-title">一般情報</h2>
                      <div className="customer-info">
                        <label htmlFor="name" className="customer-info-label">
                          フルネーム:
                        </label>
                        <span className="customer-info-content">{userInfo?.name || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="name_kana" className="customer-info-label">
                          フルネーム (カナ):
                        </label>
                        <span className="customer-info-content">{userInfo?.name_kana || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="birth_day" className="customer-info-label">
                          生年月日:
                        </label>
                        <span className="customer-info-content">{userInfo?.birth_day || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="gender" className="customer-info-label">
                          性別:
                        </label>
                        <span className="customer-info-content">
                          {userInfo?.gender === 0 ? "未選択" : userInfo?.gender === 1 ? "男性" : "女性"} </span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="phone" className="customer-info-label">
                          電話番号:
                        </label>
                        <span className="customer-info-content">{userInfo?.phone || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="email" className="customer-info-label">
                          メ-ルアドしス:
                        </label>
                        <span className="customer-info-content">{userInfo?.email || "––"}</span>
                      </div>
                    </div>
                    <div className="form-item">
                      <h2 className="form-item-title">住所情報</h2>
                      <div className="customer-info">
                        <label htmlFor="post_code" className="customer-info-label">
                          郵便番号:
                        </label>
                        <span className="customer-info-content">{userInfo?.post_code || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="prefecture" className="customer-info-label">
                          都道府県:
                        </label>
                        <span className="customer-info-content">{userInfo?.prefecture || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="city" className="customer-info-label">
                          市区町村:
                        </label>
                        <span className="customer-info-content">{userInfo?.city || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="address" className="customer-info-label">
                          住所:
                        </label>
                        <span className="customer-info-content">{userInfo?.address || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="area" className="customer-info-label">
                          番地:
                        </label>
                        <span className="customer-info-content">{userInfo?.area || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="building" className="customer-info-label">
                          物件名:
                        </label>
                        <span className="customer-info-content">{userInfo?.building || "––"}</span>
                      </div>
                    </div>
                    <div className="form-item">
                      <h2 className="form-item-title">お届け先情報</h2>
                      <div className="customer-info">
                        <label htmlFor="payment_method" className="customer-info-label">
                          お支払い方法:
                        </label>
                        <span className="customer-info-content">
                          {selectList.paymentMethodList.find(
                            (item) => item.value === userInfo?.shipping[0]?.payment_method,
                          )?.label || "––"}
                        </span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="payment_code" className="customer-info-label">
                          支払いコード (Smartpit):
                        </label>
                        <span className="customer-info-content">{userInfo?.shipping[0]?.payment_code}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="shipping_method" className="customer-info-label">
                          配送方法:
                        </label>
                        <span className="customer-info-content">
                          {selectList.shippingMethodList.find(
                            (item) => item.value === userInfo?.shipping[0]?.shipping_method,
                          )?.label || "––"}
                        </span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="shipping_code" className="customer-info-label">
                          配送コード:
                        </label>
                        <span className="customer-info-content">{userInfo?.shipping[0]?.shipping_code || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="shipping_post_code" className="customer-info-label">
                          郵便番号:
                        </label>
                        <span className="customer-info-content">{userInfo?.shipping[0]?.post_code || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="shipping_address" className="customer-info-label">
                          住所:
                        </label>
                        <span className="customer-info-content">{userInfo?.shipping[0]?.shipping_address || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="shipping_address_extension" className="customer-info-label">
                          住所拡張:
                        </label>
                        <span className="customer-info-content">
                          {userInfo?.shipping[0]?.shipping_address_extension || "––"}
                        </span>
                      </div>
                    </div>
                    <div className="form-item">
                      <h2 className="form-item-title">在留情報</h2>
                      <div className="customer-info">
                        <label htmlFor="passport" className="customer-info-label">
                          国籍:
                        </label>
                        <span className="customer-info-content">
                          {selectList.countryList.find((item) => item.value === userInfo?.passport)?.label || "––"}
                        </span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="iccd" className="customer-info-label">
                          在留カード番号:
                        </label>
                        <span className="customer-info-content">{userInfo?.iccd || "––"}</span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="coe" className="customer-info-label">
                          在留資格:
                        </label>
                        <span className="customer-info-content">
                          {selectList.coeList.find((item) => item.value === userInfo?.coe)?.label || "––"}
                        </span>
                      </div>
                      <div className="customer-info">
                        <label htmlFor="expire_time_coe" className="customer-info-label">
                          在留期限:
                        </label>
                        <span className="customer-info-content">{userInfo?.expire_time_coe || "––"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="customer-item-info-header">
                  <h1 className="customer-item-info-title">個人情報を入力</h1>
                  <div className="customer-item-info-action">
                    <div
                      className="customer-item-info-cancel customer-item-info-action-button"
                      onClick={() => {
                        setOpenEdit(false);
                        form.resetFields();
                      }}
                    >
                      キャンセル
                    </div>
                    <button className="customer-item-info-save customer-item-info-action-button" type="submit">
                      変更する
                    </button>
                  </div>
                </div>
                <div className="customer-item-info-detail">
                  <div className="form-group">
                    <div className="form-item">
                      <h2 className="form-item-title">一般情報</h2>
                      <Form.Item
                        label={<span className="customer-info-label">フルネーム</span>}
                        name="name"
                        className="customer-form-item"
                        rules={[
                          { required: true, message: "Vui lòng nhập họ và tên" },
                          {
                            pattern: /^[A-Z ]+$/,
                            message: "Họ tên là ký tự in hoa không dấu",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="NGUYEN VAN A"
                          className="customer-form-input"
                          onInput={handleUpperCase}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-info-label">フルネーム (カナ)</span>}
                        name="name_kana"
                        className="customer-form-item"
                        rules={[
                          { required: true, message: "Vui lòng nhập họ (Kana)" },
                          {
                            pattern: /^([ァ-ン]|ー)+$/,
                            message: "Họ tên katakana là ký tự fullwidth (全角)",
                          },
                        ]}
                      >
                        <Input type="text" placeholder="全角のみ" className="customer-form-input" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-info-label">生年月日</span>}
                        name="birth_day"
                        className="customer-form-item"
                        rules={[{ required: true, message: "Vui lòng chọn ngày sinh" }]}
                      >
                        <DatePicker className="customer-form-input" format="YYYY-MM-DD" placeholder="Chọn ngày sinh" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-info-label">性別</span>}
                        name="gender"
                        className="customer-form-item"
                        rules={[{ required: true, message: "Vui lòng chọn giới tính" }]}
                      >
                        <Select options={selectList.genderList} className="customer-form-input" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">電話番号</span>}
                        name="phone"
                        rules={[
                          { required: true, message: "Vui lòng nhập số điện thoại" },
                          { pattern: /^[0-9]*$/, message: "Vui lòng nhập đúng định dạng là số" },
                          { min: 10, max: 11, message: "Vui lòng nhập số có độ dài từ 10 - 11 kí tự" },
                        ]}
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập số điện thoại" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">メールアドレス</span>}
                        name="email"
                        rules={[
                          { required: true, message: "Vui lòng nhập email" },
                          {
                            type: "email",
                            message: "Vui lòng nhập đúng định dạng email",
                          },
                        ]}
                      >
                        <Input type="email" className="customer-form-input" placeholder="Nhập email" />
                      </Form.Item>
                    </div>
                    <div className="form-item">
                      <h2 className="form-item-title">住所情報</h2>
                      <Form.Item
                        label={<span className="customer-form-label">郵便番号</span>}
                        name="post_code"
                        rules={[
                          { required: true, message: "Vui lòng nhập mã bưu điện" },
                          {
                            pattern: /^[0-9]+$/,
                            min: 7,
                            max: 7,
                            message: "Vui lòng nhập đúng định dạng là số và 7 ký tự",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          inputMode="numeric"
                          className="customer-form-input"
                          onChange={handlePostCodeChange}
                          placeholder="Nhập mã bưu điện"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">都道府県</span>}
                        name="prefecture"
                        rules={[{ required: true, message: "Vui lòng nhập tỉnh" }]}
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập tỉnh" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">市区町村</span>}
                        name="city"
                        rules={[{ required: true, message: "Vui lòng nhập thành phố" }]}
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập thành phố" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">住所</span>}
                        name="address"
                        rules={[{ required: true, message: "Vui lòng nhập địa chỉ" }]}
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập địa chỉ" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">番地</span>}
                        name="area"
                        rules={[{ required: true, message: "Vui lòng nhập mã khu vực" }]}
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập mã khu vực" />
                      </Form.Item>
                      <Form.Item label={<span className="customer-form-label">物件名</span>} name="building">
                        <Input type="text" className="customer-form-input" placeholder="Nhập toà nhà" />
                      </Form.Item>
                    </div>
                    <div className="form-item">
                      <h2 className="form-item-title">お届け先情報</h2>
                      <Form.Item
                        label={<span className="customer-form-label">お支払い方法</span>}
                        name="payment_method"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn phương thức thanh toán",
                          },
                          { type: "string", message: "Vui lòng chọn phương thức thanh toán" },
                          { whitespace: true, message: "Vui lòng chọn phương thức thanh toán" },
                        ]}
                      >
                        <Select options={selectList.paymentMethodList} placeholder="Chọn phương thức thanh toán" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">支払いコード (Smartpit)</span>}
                        name="payment_code"
                        rules={[
                          { required: true, message: "Vui lòng nhập mã thanh toán Smartpit" },
                          { pattern: /^[0-9]*$/, message: "Vui lòng nhập đúng định dạng là số" },
                        ]}
                      >
                        <Input
                          type="text"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          className="customer-form-input"
                          placeholder="Nhập mã thanh toán Smartpit"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">配送方法</span>}
                        name="shipping_method"
                        rules={[{ required: true, message: "Vui lòng chọn phương thức giao hàng" }]}
                      >
                        <Select options={selectList.shippingMethodList} placeholder="Chọn phương thức giao hàng" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">配送コード</span>}
                        name="shipping_code"
                        rules={[
                          { required: true, message: "Vui lòng nhập mã giao hàng" },
                          {
                            pattern: /^[0-9]*$/,
                            message: "Vui lòng nhập đúng định dạng là số",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          inputMode="numeric"
                          className="customer-form-input"
                          placeholder="Nhập mã giao hàng"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">郵便番号</span>}
                        name="shipping_post_code"
                      >
                        <Input
                          type="text"
                          className="customer-form-input"
                          placeholder="Nhập mã bưu điện"
                          onChange={handlePostCodeChange}
                        />
                      </Form.Item>
                      <Form.Item label={<span className="customer-form-label">住所</span>} name="shipping_address01">
                        <Input type="text" className="customer-form-input" placeholder="Nhập địa chỉ" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">住所拡張</span>}
                        name="shipping_address_extension"
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập địa chỉ mở rộng" />
                      </Form.Item>
                    </div>
                    <div className="form-item">
                      <h2 className="form-item-title">在留情報</h2>
                      <Form.Item
                        label={<span className="customer-form-label">国籍</span>}
                        name="passport"
                        rules={[{ required: true, message: "Vui lòng chọn quốc tịch" }]}
                      >
                        <Select
                          options={selectList.countryList}
                          className="customer-form-input"
                          placeholder="Chọn quốc tịch"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">在留カード番号</span>}
                        name="iccd"
                        rules={[{ required: true, message: "Vui lòng nhập mã thẻ lưu trú" }]}
                      >
                        <Input type="text" className="customer-form-input" placeholder="Nhập mã thẻ lưu trú" />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">在留資格</span>}
                        name="coe"
                        rules={[{ required: true, message: "Vui lòng chọn hình thức lưu trú" }]}
                      >
                        <Select
                          options={selectList.coeList}
                          className="customer-form-input"
                          placeholder="Chọn hình thức lưu trú"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="customer-form-label">在留期限</span>}
                        name="expire_time_coe"
                        rules={[{ required: true, message: "Vui lòng chọn thời hạn lưu trú" }]}
                      >
                        <DatePicker
                          className="customer-form-input"
                          format="YYYY-MM-DD"
                          minDate={dayjs().add(7, "day")}
                          placeholder="Chọn thời hạn lưu trú"
                        />
                      </Form.Item>
                    </div>
                    {userInfo?.verify?.length > 0 ? (
                      ""
                    ) : (
                      <div className="form-item">
                        <h2 className="form-item-title">在留カード</h2>
                        <Form.Item
                          label={<span className="customer-form-label">前面</span>}
                          name="image_front"
                          wrapperCol={{ span: 19 }}
                          rules={[{ required: true, message: "Vui lòng tải lên ảnh mặt trước" }]}
                        >
                          <div className="customer-form-upload">
                            <Upload {...propsUpload("image_front")} name="image_front">
                              <Button className="customer-form-button">
                                <span className="customer-form-button-icon">
                                  <i className="fa-light fa-upload"></i>
                                </span>
                                <span className="customer-form-button-text">アップロード</span>
                              </Button>
                            </Upload>
                            <div className="customer-form-upload-sample">
                              <img src={images.image_front_sample} alt="" />
                            </div>
                          </div>
                        </Form.Item>
                        <Form.Item
                          label={<span className="customer-form-label">裏面</span>}
                          name="image_end"
                          wrapperCol={{ span: 19 }}
                          rules={[{ required: true, message: "Vui lòng tải lên ảnh mặt sau" }]}
                        >
                          <div className="customer-form-upload">
                            <Upload {...propsUpload("image_end")} name="image_end">
                              <Button className="customer-form-button">
                                <span className="customer-form-button-icon">
                                  <i className="fa-light fa-upload"></i>
                                </span>
                                <span className="customer-form-button-text">アップロード</span>
                              </Button>
                            </Upload>
                            <div className="customer-form-upload-sample">
                              <img src={images.image_end_sample} alt="" />
                            </div>
                          </div>
                        </Form.Item>
                        <Form.Item
                          label={<span className="customer-form-label">顔写真</span>}
                          name="image_face"
                          wrapperCol={{ span: 19 }}
                          rules={[{ required: true, message: "Vui lòng tải lên ảnh chụp cầm thẻ" }]}
                        >
                          <div className="customer-form-upload">
                            <Upload {...propsUpload("image_face")} name="image_face">
                              <Button className="customer-form-button">
                                <span className="customer-form-button-icon">
                                  <i className="fa-light fa-upload"></i>
                                </span>
                                <span className="customer-form-button-text">アップロード</span>
                              </Button>
                            </Upload>
                            <div className="customer-form-upload-sample">
                              <img src={images.image_face_sample} alt="" />
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </Form>
              */}
      <div className="main">
        <div className="main-content">
          {statusVerify === 0 ? (
            <div className="customer-notification">
              <span className="customer-notification-icon">
                <i className="fa-light fa-circle-exclamation"></i>
              </span>
              <span className="customer-notification-text">
                Tài khoản của bạn chưa được xác thực. Vui lòng cập nhật thông tin xác thực.
              </span>
            </div>
          ) : statusVerify === 2 ? (
            <div className="customer-notification">
              <span className="customer-notification-icon">
                <i className="fa-light fa-circle-exclamation"></i>
              </span>
              <span className="customer-notification-text">
                Tài khoản của bạn không được xác thực. Vui lòng liên hệ với bộ phận CSKH.
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="info-list">
            <div className="info-item">
              <div className="customer-info">
                <h2 className="customer-info-main-title">{t("common:thong_tin_khach_hang")}</h2>
                <Row className="customer-info-main-content">
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("ho_ten")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.name}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("ho_ten_kana")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.name_kana}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("ngay_sinh")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.birth_day}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("gioi_tinh")}: </span>
                    <span className="customer-info-main-content-item-text">
                      {userInfo?.gender === 0 ? "--" : userInfo?.gender === 1 ? t("nam") : t("nu")}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("so_dien_thoai")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.phone}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("email")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.email}</span>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="info-item">
              <Tabs
                defaultActiveKey="1"
                items={tabsItem}
                className="customer-info-tabs"
                onChange={() => setOpenEdit(false)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      {/* </div> */}
      {/* <div className={`overlay${openSidebar ? " active" : ""}`} onClick={() => setOpenSidebar(!openSidebar)}></div> */}
    </div>
  );
};
