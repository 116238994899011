import { useEffect, useState } from "react";
import { Carousel, Collapse, Progress, Tooltip } from "antd";
// import { Header } from "../../components/Header";
// import { Sidebar } from "../../components/Sidebar";
import { Link } from "react-router-dom";
// import { Footer } from "../../components/Footer";
import { local } from "../../helper/localStorage";
import overLoader from "../../components/overLoader";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { ContractInfo, QuotaInfo, UserInfo } from "../../types/common";
import { getContractInfo, getInfoAccount, getQuotaInfo } from "../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const [month, setMonth] = useState<number>(4);
  //   const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  //   const [collapseSidebar, setCollapseSidebar] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string | string[]>(["0"]);
  const [language, setLanguage] = useState<string>("ja");

  // redux
  const dispatch = useAppDispatch();
  const authLoading = useAppSelector((state) => state.auth.loading);
  const userLoading = useAppSelector((state) => state.user.loading);
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  const quotaInfo: QuotaInfo = useAppSelector((state) => state.user.quotaInfo) as QuotaInfo;
  const contractInfo: ContractInfo[] = useAppSelector((state) => state.user.contractInfo || []) as ContractInfo[];

  const token: any = local.StorageGetSecure("access_token");
  const languageLocal = local.StorageGetSecure("language");
  const { t, i18n } = useTranslation(["dashboard", "common"]);

  useEffect(() => {
    if (token) {
      dispatch(getInfoAccount());
      dispatch(getContractInfo());
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (userInfo?.list_sim?.length > 0) {
      dispatch(getQuotaInfo(userInfo?.list_sim[0]));
    }
  }, [dispatch, userInfo]);
  useEffect(() => {
    // if (languageLocal) {
    //   i18n.changeLanguage(languageLocal);
    // }
    i18n.changeLanguage(languageLocal || "ja");
    setLanguage(languageLocal || language);
  }, [i18n, language, languageLocal]);

  const bannerList = [
    {
      image:
        "https://images.unsplash.com/photo-1717153982802-b0bd0b2eb579?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0Mnx8fGVufDB8fHx8fA%3D%3D",
      alt: "",
    },
    {
      image:
        "https://images.unsplash.com/photo-1717540982852-4cbe480b5aca?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0OHx8fGVufDB8fHx8fA%3D%3D",
      alt: "",
    },
    {
      image:
        "https://images.unsplash.com/photo-1717563281745-0cab0a6d60f6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1Mnx8fGVufDB8fHx8fA%3D%3D",
      alt: "",
    },
  ];
  const promotionList = [
    {
      image:
        "https://plus.unsplash.com/premium_photo-1681487557151-d15812c252ed?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvbW90aW9ufGVufDB8fDB8fHww",
      alt: "",
      text: "Khuyến mãi 20% giá trị thẻ nạp",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore repellendus quos assumenda iure exercitationem totam dignissimos quaerat sit rem in saepe amet animi voluptatem consequatur id, laboriosam sequi suscipit ducimus!",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1681487557151-d15812c252ed?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvbW90aW9ufGVufDB8fDB8fHww",
      alt: "",
      text: "Khuyến mãi 20% giá trị thẻ nạp",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore repellendus quos assumenda iure exercitationem totam dignissimos quaerat sit rem in saepe amet animi voluptatem consequatur id, laboriosam sequi suscipit ducimus!",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1681487557151-d15812c252ed?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvbW90aW9ufGVufDB8fDB8fHww",
      alt: "",
      text: "Khuyến mãi 20% giá trị thẻ nạp",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore repellendus quos assumenda iure exercitationem totam dignissimos quaerat sit rem in saepe amet animi voluptatem consequatur id, laboriosam sequi suscipit ducimus!",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1681487557151-d15812c252ed?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvbW90aW9ufGVufDB8fDB8fHww",
      alt: "",
      text: "Khuyến mãi 20% giá trị thẻ nạp",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore repellendus quos assumenda iure exercitationem totam dignissimos quaerat sit rem in saepe amet animi voluptatem consequatur id, laboriosam sequi suscipit ducimus!",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1681487557151-d15812c252ed?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvbW90aW9ufGVufDB8fDB8fHww",
      alt: "",
      text: "Khuyến mãi 20% giá trị thẻ nạp",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore repellendus quos assumenda iure exercitationem totam dignissimos quaerat sit rem in saepe amet animi voluptatem consequatur id, laboriosam sequi suscipit ducimus!",
    },
  ];

  const handleShowDataUsage = async (phoneNumber: string) => {
    await dispatch(getQuotaInfo(phoneNumber));
  };
  const handleCollapseChange = (key: string | string[]) => {
    if (key !== activeKey) {
      handleShowDataUsage(userInfo?.list_sim[Number(key)]);
    }
    setActiveKey(key);
  };
  const phoneNumberList: Array<any> = userInfo?.list_sim?.map((item: any, index: number) => {
    return {
      key: index,
      label: <span className="phone-number-item">{item}</span>,
      children: !Array.isArray(quotaInfo) ? (
        userLoading ? (
          <div className="data-usage">
            <span className="phone-number-item">{t("common:dang_tai_du_lieu")}</span>
          </div>
        ) : (
          <div className="data-usage">
            <span className="data-package-name">{quotaInfo?.planCode}</span>
            <div className="data-usage-chart">
              <Tooltip title={`${t("da_su_dung")} ${quotaInfo?.used} MB`} color="#4533f8">
                <Progress
                  type="circle"
                  strokeWidth={8}
                  strokeColor="#8af0c1"
                  trailColor="#bebebe"
                  percent={quotaInfo?.remain ? (quotaInfo?.remain / quotaInfo?.total) * 100 : 0}
                  format={() => (
                    <div className="data-info">
                      <p className="data-info-text">{t("dung_luong_tong")}</p>
                      <span className="data-info-number">{quotaInfo?.total}</span>
                      <span className="data-info-unit">MB</span>
                    </div>
                  )}
                />
              </Tooltip>
            </div>
            <button className="data-buy">
              <span className="data-buy-icon">
                <i className="fa-sharp fa-solid fa-coins"></i>
              </span>
              <span className="data-buy-text">{t("mua_them_du_lieu")}</span>
            </button>
            <Link to="/service_info" className="check-info check-contract">
              <span className="check-info-icon">
                <i className="fa-light fa-gear"></i>
              </span>
              <span className="check-info-text">{t("kiem_tra_hop_dong")}</span>
            </Link>
            <Link to="/#" className="check-info check-history">
              <span className="check-info-icon">
                <i className="fa-light fa-rectangle-list"></i>
              </span>
              <span className="check-info-text">{t("kiem_tra_lich_su")}</span>
            </Link>
          </div>
        )
      ) : (
        <div className="data-usage">
          <span className="phone-number-item">{t("common:khong_co_du_lieu")}</span>
        </div>
      ),
    };
  });

  const convertNameToFullWidth = (name: string) => {
    return name.replace(/[A-Za-z0-9]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0xfee0));
  };
  //   console.log(contractInfo[0]?.package_price);
  return (
    <div className="wrapper dashboard">
      {authLoading && overLoader()}
      {userLoading && overLoader()}
      {/* {userInfo ? "" : overLoader()} */}
      {/* <Sidebar
        openSidebar={openSidebar}
        collapseSidebar={collapseSidebar}
        handleCollapseSidebar={() => setCollapseSidebar(!collapseSidebar)}
      /> */}
      <div className="main">
        {/* <Header handleToggleSidebar={() => setOpenSidebar(!openSidebar)} /> */}
        <div className="main-content">
          <Carousel autoplay className="main-content-banner">
            {bannerList.map((item, index) => (
              <div className="main-content-banner-item" key={index}>
                <img src={item.image} alt={item.alt} />
              </div>
            ))}
          </Carousel>
          <div className="main-content-dashboard">
            <div className="dashboard-list">
              <div className="dashboard-item">
                <div className="user-info">
                  <h1 className="user-info-title">{t("common:thong_tin_khach_hang")}</h1>
                  <h2 className="user-info-subtitle">{t("common:thong_tin_khach_hang")}</h2>
                  <span className="user-info-name">
                    {userInfo?.name_kana
                      ? convertNameToFullWidth(`${userInfo?.name} ${userInfo?.name_kana}`)
                      : convertNameToFullWidth(`${userInfo?.name}`)}
                  </span>
                  <Link to="/customer_info" className="user-info-detail">
                    {t("common:chi_tiet")}
                  </Link>
                </div>
              </div>
              <div className="dashboard-item">
                <div className="balance-info">
                  <h1 className="balance-info-title">{t("so_du_tai_khoan")}</h1>
                  <div className="balance-info-detail">
                    <div className="balance-info-detail-amount">
                      <div className="amount-money-icon">
                        <i className="fa-solid fa-coins"></i>
                      </div>
                      <div className="amount-money-text">
                        <span className="amount-money-number">{userInfo?.wallet}</span>
                        <span className="amount-money-unit">円</span>
                      </div>
                    </div>
                    <div className="balance-info-detail-action">
                      <a href="/#" className="recharge">
                        <span className="recharge-icon">
                          <i className="fa-light fa-circle-dollar"></i>
                        </span>
                        <span className="recharge-text">{t("nap_tien")}</span>
                      </a>
                      <a href="/#" className="payment">
                        {t("phuong_thuc_thanh_toan")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-item">
                <div className="phone-number">
                  <h1 className="phone-number-title">{t("danh_sach_so_dien_thoai")}</h1>
                  <div className="phone-number-list">
                    {userInfo?.list_sim.length > 0 ? (
                      <Collapse
                        items={phoneNumberList}
                        defaultActiveKey={["0"]}
                        onChange={(key) => key.length > 0 && handleCollapseChange(key)}
                        accordion
                        ghost
                        className="phone-number-list-accordion"
                      />
                    ) : (
                      <span className="phone-number-item">{t("common:khong_co_du_lieu")}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="dashboard-item">
                <div className="usage-fee">
                  <div className="usage-fee-item">
                    <div className="monthly-payment-fee">
                      <h1 className="monthly-payment-fee-title">{t("cuoc_phi_su_dung")}</h1>
                      <div className="monthly-payment-fee-slider">
                        <button
                          className={`monthly-payment-fee-slider-button prev
                            ${month === 1 ? "disabled" : ""}
                        `}
                          onClick={() => month > 1 && setMonth(month - 1)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <div className="monthly-payment-fee-content">
                          {languageLocal === "vi" ? (
                            <>
                              <span className="monthly-payment-fee-content-text">{t("phi_thanh_toan_thang")} </span>
                              <span className="monthly-payment-fee-content-month">{month}</span>
                            </>
                          ) : (
                            <>
                              <span className="monthly-payment-fee-content-month">{month}</span>
                              <span className="monthly-payment-fee-content-text">{t("phi_thanh_toan_thang")}</span>
                            </>
                          )}
                        </div>
                        <button
                          className={`monthly-payment-fee-slider-button next
                            ${month === 12 ? "disabled" : ""}
                        `}
                          onClick={() => month < 12 && setMonth(month + 1)}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="usage-fee-item">
                    <div className="payment-status">
                      <span className="payment-status-label">{t("da_thanh_toan")}</span>
                      <div className="payment-status-detail">
                        <div className="payment-status-detail-amount">
                          <span className="payment-status-detail-amount-text">{t("common:tong")}: </span>
                          <span className="payment-status-detail-amount-number">
                            {/* find status = 1 and calc total package_price where status = 1 */}
                            {contractInfo
                              ?.filter((item) => item.status === 1)
                              .reduce((acc, item) => acc + item.package_price, 0)}
                          </span>
                          <span className="payment-status-detail-amount-unit"> 円</span>
                        </div>
                        <div className="payment-status-detail-action">
                          <a href="/#" className="payment-status-detail-action-item recharge">
                            <span className="recharge-icon">
                              <i className="fa-light fa-circle-dollar"></i>
                            </span>
                            <span className="recharge-text">{t("nap_tien")}</span>
                          </a>
                          <a href="/#" className="payment-status-detail-action-item check-fee">
                            {t("kiem_tra_so_tien_can_thanh_toan")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="usage-fee-item">
                    <div className="promotion">
                      <h1 className="promotion-title">{t("khuyen_mai")}</h1>
                      <div className="promotion-list">
                        {promotionList.slice(-3).map((item, index) => (
                          <Link to="/#" className="promotion-item" key={index}>
                            <div className="promotion-item-image">
                              <img src={item.image} alt={item.alt} />
                            </div>
                            <div className="promotion-item-info">
                              <h2 className="promotion-item-text">{item.text}</h2>
                              <p className="promotion-item-description">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
      {/* <div className={`overlay${openSidebar ? " active" : ""}`} onClick={() => setOpenSidebar(!openSidebar)}></div> */}
    </div>
  );
};
